import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { isEmpty, cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';

import {
  isLayoutFooterEnabled,
  reactiveTemplateConfigSelector,
} from 'common/selectors/theme';
import {
  getFooterSelector,
  getTopFooterMenuSelector,
} from 'common/selectors/page';

import {
  getLogoSelector,
  getWebInstanceEmailsSelector,
  getWebInstanceSettingsSelector,
} from 'common/selectors';

import loadable from '@loadable/component';
const FooterVariant = loadable((props) =>
  import(`common/blocks/footer/${props.blockVariant}`)
);

class Footer extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const {
      t,
      categories,
      staticPages,
      logo,
      mapAddress,
      webInstanceEmails = {},
      settings = {},
      blockSettings,
    } = this.props;

    if (!_.get(blockSettings, 'isFooterEnabled')) {
      return null;
    }

    const footerData = {
      t: t,
      logo: logo,
      data: {
        description: _.get(settings, 'seo_description', false),
        phone: _.get(settings, 'phone', false),
        mail: _.get(webInstanceEmails, 'adminEmail', false),
        facebook: {
          active: _.get(settings, 'facebook_link', false) ? true : false,
          link: _.get(settings, 'facebook_link', false),
          name: _.get(settings, 'facebook_name', false)
            ? settings.facebook_name
            : 'Facebook',
        },
        instagram: {
          active: _.get(settings, 'instagram_link', false) ? true : false,
          link: _.get(settings, 'instagram_link', false),
          name: _.get(settings, 'instagram_name', false)
            ? settings.instagram_name
            : 'Instagram',
        },
        google: {
          active: _.get(settings, 'google_link', false) ? true : false,
          link: _.get(settings, 'google_link', false),
          name: _.get(settings, 'google_name', false)
            ? settings.google_name
            : 'Google',
        },
        youtube: {
          active: _.get(settings, 'youtube_link', false) ? true : false,
          link: _.get(settings, 'youtube_link', false),
          name: _.get(settings, 'youtube_name', false)
            ? settings.youtube_name
            : 'YouTube',
        },
        map: {
          active: true,
          link: mapAddress,
          name: t('show on map'),
        },
        address: {
          name: settings.name_of_company,
          street: settings.street_and_number,
          zip: settings.ZIP,
          city: settings.city,
          country: settings.state,
        },
      },
      categories: categories,
      pages: staticPages,
    };

    return (
      <FooterVariant
        blockVariant={blockSettings.blockVariant}
        {...footerData}
      />
    );
  }
}

const makeMapStateToProps = () => {
  const getFooter = getFooterSelector();
  const getFooterPages = getTopFooterMenuSelector();
  const getLogo = getLogoSelector();
  const getWebInstanceEmails = getWebInstanceEmailsSelector();
  const getWebInstanceSettings = getWebInstanceSettingsSelector();
  const isFooterEnabled = isLayoutFooterEnabled();
  const reactiveTemplateConfig = reactiveTemplateConfigSelector(
    'FooterVariant'
  );
  return (state, props) => {
    const propsToReturn = {};
    const footer = getFooter(state, props);
    const logo = getLogo(state, props);
    const webInstanceEmails = getWebInstanceEmails(state, props);
    const settings = getWebInstanceSettings(state, props);
    propsToReturn.logo = logo;
    if (footer) {
      const footerPages = getFooterPages(state, props);
      if (!isEmpty(footerPages)) {
        propsToReturn.staticPages = cloneDeep(footerPages);
      }
      if (!isEmpty(footer.categories)) {
        propsToReturn.categories = footer.categories;
      }
    }
    if (webInstanceEmails) {
      propsToReturn.webInstanceEmails = webInstanceEmails;
    }
    if (settings) {
      propsToReturn.settings = settings;
    }
    if (_.get(settings, 'street_and_number') && _.get(settings, 'city')) {
      propsToReturn.mapAddress =
        'https://maps.google.com/?q=' +
        encodeURIComponent(_.get(settings, 'street_and_number')) +
        '+' +
        encodeURIComponent(_.get(settings, 'city'));
    }

    propsToReturn.blockSettings = {
      blockVariant: reactiveTemplateConfig(state, props),
      isFooterEnabled: isFooterEnabled(state, props),
    };

    return propsToReturn;
  };
};

const FooterConnected = connect(makeMapStateToProps)(Footer);

export default withTranslation()(FooterConnected);
